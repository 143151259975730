import gql from 'graphql-tag'
import { FULL_ORDER_FRAGMENT } from '../fragments/OrderFragments'

export const FULL_TREATMENT_PLAN_FRAGMENT = gql`
  fragment FullTreatmentPlan on TreatmentPlan {
    __typename
    id
    reference
    treatmentDate
    treatmentTime
    treatmentSource
    poNumber
    shipDate
    specialDeal
    alertData
    deliveryDate
    deliveryTime
    notForSap
    sapBillingCode
    invoiceNotes
    treatmentReference
    treatingPhysicianId
    treatingPhysicianName
    treatmentIntent
    billingBlock
    createdAt
    customer {
      __typename
      id
      name
      country
      nrTsNumbers
      nordionAccountNumber
      alertData
      specialDeal
      inheritAddressesFromDistributor
    }
    address {
      id
      city
      country
      freightTerms
      incoterms
      incotermsLocation
      incotermsYear
      line1
      line2
      line3
      nordionShipToId
      primary
      state
      zip
    }
    distributorAddress {
      id
      city
      country
      freightTerms
      incoterms
      incotermsLocation
      incotermsYear
      line1
      line2
      line3
      nordionShipToId
      primary
      state
      zip
    }
    distributor {
      id
      name
      nordionAccountNumber
    }
    orders {
      ...FullOrder
    }
    sameDayShipping
    sameDayShippingReason {
      id
      code
      description
    }
    attachments {
      id
      filename
      extension
      url
      downloadUrl
    }
  }
  ${FULL_ORDER_FRAGMENT}
`
