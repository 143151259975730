export interface IconProps {
  fill?: string
  width?: string | number
  height?: string | number
  title?: string
  rotate?: number
}

export enum DeliveryTypeEnum {
  STANDARD_DELIVERY = 'STANDARD_DELIVERY',
  EXPEDITED_DELIVERY = 'EXPEDITED_DELIVERY',
}
